import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '../../request';
import { connect } from 'react-redux';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../reducers/actions/exame';
import axios from 'axios';
import { MODALIDADE } from '../../common/Constants';
import { SttLoading, SttAlerta, SttGrid, SttButton, SttModal, SttAutocomplete, SttTranslate } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));
const Priorizar = (props) => {

    const { strings, priorizar, setPriorizar, idExame, idInstituicao, setAtualizarBusca, callback } = props;
    const classes = useStyles();
    
    const [motivosPriorizacao, setMotivosPriorizacao] = useState([]);
    const [motivo, setMotivo] = useState(null);
    const [confirmarPriorizar, setConfirmarPriorizar] = useState(false);
    const [priorizando, setPriorizando] = useState(false);
    const [alerta, setAlerta] = useState(false);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('success');
    const [tituloAlerta, setTituloAlerta] = useState(strings.sucesso);
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    useEffect(() => {

        // Carrega a lista de motivos de priorização de exames
        if (!motivosPriorizacao.length) {
            axios
                .get(`${EXAME_API_BASE_URL}/motivo-priorizacao`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        setMotivosPriorizacao(response.data);
                    }
                })
                .catch(err => console.log(err));
        }
    }, []);

    const fecharPriorizar = () => {
        setMotivo(null);
        setPriorizar(false);
    }

    const selecionarMotivo = (event, novoMotivo) => {
        setMotivo(novoMotivo);
    }

    const priorizarExame = () => {
        setConfirmarPriorizar(false);
        setPriorizando(true);

        const dados = {
            exame: idExame,
            motivo: motivo.id,
            instituicao: idInstituicao,
            modalidade: MODALIDADE.DESCRICAO_PT
        }
        axios
            .post(`${EXAME_API_BASE_URL}/priorizar`, dados, { headers: getHeaders() })
            .then((resposta) => {
                setPriorizando(false);
                setTipoAlerta('success');
                setMensagemAlerta(strings.examePriorizado);
                setAlerta(true);
                setOpcoesAlerta([
                    { 
                        title: strings.ok, 
                        onClick: () => {
                            setAlerta(false);
                            fecharPriorizar();
                            if (callback) {
                                callback();
                            }
                            setAtualizarBusca(true);
                        } 
                    }
                ]);
            })
            .catch(err => {
                setPriorizando(false);
                console.log(err);
                setTipoAlerta('error');
                setTituloAlerta(strings.erro)
                setMensagemAlerta(strings.erroPriorizarExame);
                setOpcoesAlerta([{ title: strings.ok, onClick: () => setAlerta(false) }]);
                setAlerta(true);
            });
    }

    return (
        <>
            <SttModal
                title={strings.priorizar}
                open={priorizar}
                outModalClose={fecharPriorizar}
                iconClose={fecharPriorizar}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttAutocomplete
                                    inputprops={{
                                        label: strings.motivo,
                                        required: true
                                    }}
                                    getOptionLabel={option => option.descricao}
                                    options={motivosPriorizacao}
                                    value={motivo}
                                    onChange={selecionarMotivo}
                                />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" disabled={!motivo} onClick={() => setConfirmarPriorizar(true)}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharPriorizar}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <SttAlerta
                open={confirmarPriorizar}
                title={strings.atencao}
                message={strings.confirmarPriorizarExame}
                type={'alert'}
                onClose={() => {
                    setConfirmarPriorizar(false);
                }}
                options={
                    [
                        {
                            title: strings.sim,
                            onClick: priorizarExame
                        },
            
                        {
                            title: strings.nao,
                            onClick: () => {
                                setConfirmarPriorizar(false);
                            }
                        }
                    ]
                }
            />
            <SttAlerta
                open={alerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={() => setAlerta(false)}
            />

            <div className={classes.carregando}>
                <SttLoading
                    open={priorizando}
                    text={strings.priorizandoExame}
                />
            </div>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setAtualizarBusca: atualizar => dispatch(setAtualizarBuscaAction(atualizar))
    }
}

export default connect(null, mapDispatchToProps)(SttTranslate('Exame')(Priorizar));
