module.exports = {
    agenda: 'Agenda',
    agendadores: 'Agendadores',
    agendamento: 'Agendamento',
    alteracaoRede: 'Alteração de rede',
    aoVivo: 'Ao vivo',
    cadastroCategoria: 'Cadastro de categoria',
    cadastroRede: 'Cadastro de rede',
    categoria: 'Categoria',
    categorias: 'Categorias',
    confirmarAlterarAgendamento: 'Você tem certeza que deseja alterar os dados deste agendamento?',
    confirmarAlterarCategoria: 'Você tem certeza que deseja alterar esta categoria?',
    confirmarAlterarRede: 'Você tem certeza que deseja alterar esta rede?',
    confirmarSalvarAgendamento: 'Você tem certeza que deseja realizar este agendamento?',
    confirmarSalvarCategoria: 'Você tem certeza que deseja cadastrar esta categoria?',
    confirmarSalvarRede: 'Você tem certeza que deseja cadastrar esta rede?',
    convite: 'Convite',
    convites: 'Convites',
    convitesLivre: 'Convites livres',
    dataHoraInicio: 'Data/hora de início',
    dataHoraTermino: 'Data/hora de término',
    erroParticipanteDuplicado: 'Este participante já foi adicionado anteriormente.',
    erroAssuntoMinimo5Caracteres: 'O assunto deve ter no mínimo 5 (cinco) caracteres válidos.',
    erroParticipanteObrigatorio: 'É obrigatório a inclusão de ao menos um participante no agendamento',
    eventos: 'Eventos',
    eventosFinalizados: 'Eventos finalizados',
    filtroFuncionario: 'Filtro de funcionários',
    instrucaoCadastroCategoria: `<span>- A partir dessa tela, é possível pesquisar e cadastrar categorias de redes.</span>
                                <span>- A partir dessa tela, também é possível alterar e inativar categorias de redes previamente cadastradas.</span>`,
    instrucaoCadastroRede: `<span>- A partir dessa tela, é possível pesquisar e cadastrar redes.</span>
                                <span>- A partir dessa tela, também é possível alterar e inativar redes previamente cadastradas.</span>`,
    participanteAdicionado: 'Participante adicionado',
    participantes: 'Participantes',
    participantesPrincipais: 'Participantes principais',
    permiteRegistro: 'Permite registro',
    permiteRegistroAtendimento: 'Permite registro do atendimento?',
    proximosEventos: 'Próximos eventos',
    rede: 'Rede',
    redes: 'Redes',
    sucessoSalvarAgendamento: 'Agendamento salvo com sucesso',
    tipoFuncionamento: 'Tipo de funcionamento'
}